import React from 'react';
import { Link } from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

const Patterns = ({ patterns, lang }) => {
  const link = lang === 'pl' ? `/${lang}/patterns/` : '/patterns/';
  return (
    <section className="patterns-container">
      <section className="grid-portfolio">
        { patterns.map((pattern) => (
          <Link key={pattern.node.frontmatter.slug} to={`${link}${pattern.node.frontmatter.slug}`} aria-label="Collection" className={`pattern-link ${pattern.node.frontmatter.style}`}>
            <Img
              fluid={pattern.node.frontmatter.mainImage.childImageSharp.fluid}
              alt={pattern.node.frontmatter.mainImageAlt}
              className="pattern-image"
            />
            <div className="pattern-overlay">
              <span className="overlay-content">{pattern.node.frontmatter.title}</span>
            </div>
          </Link>
        ))}
      </section>
      <h3>
        {' '}
        <FormattedMessage id="patterns.header" />
      </h3>
      <p>
        <FormattedMessage
          id="patterns.description"
          values={{
            url: (urlPlaceholder) => (
              <Link
                to={`${link}/contact`}
                className="link-pattern-contact"
              >
                {urlPlaceholder}
              </Link>
            ),
          }}
        />
      </p>
    </section>
  );
};
export default Patterns;
