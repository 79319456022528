import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layouts/main/Layout';
import Patterns from '../components/Patterns/Patterns';
import { SiteContext } from '../context/SiteContextProvider';
import SEO from '../util/SEO/SEO';


const pattern = ({ pageContext: { locale }, ...props }) => {
  const intl = useIntl();

  const { edges: posts } = props.data.patternPosts;
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <SEO
        locale={intl.locale}
        title={intl.formatMessage({ id: 'patterns.SEOtitle' })}
        description={intl.formatMessage({ id: 'patterns.SEODescription' })}
        keywords={intl.formatMessage({ id: 'SEOkeywords.keywords' })}
      />
      <SiteContext.Consumer>
        {(context) => (
          <Patterns patterns={posts.filter((post) => post.node.frontmatter.locale === context.data.locale)} lang={context.data.locale} />
        )}
      </SiteContext.Consumer>
    </Layout>
  );
};

pattern.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default pattern;

export const pageQuery = graphql`
  query PatternsContent($locale: String) {
    patternPosts: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageKey: { eq: "page_patternpost" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            path
            templateKey
            locale
            pageKey
            title
            slug
            style
            mainImage {
              childImageSharp {
                fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          mainImageAlt
          }
        }
      }
    }
  }
`;
