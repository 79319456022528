import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { SiteContext } from '../../context/SiteContextProvider';

function SEO({
  description, locale, keywords, title,
}) {
  return (
    <SiteContext.Consumer>
      {(context) => (

        <Helmet
          htmlAttributes={{
            lang: locale,
          }}
          title={title}

          meta={[
            {
              name: 'robots',
              content: 'index, follow',
            },
            {
              name: 'googlebot',
              content: 'index, follow',
            },
            {
              name: 'googlebot-news',
              content: 'index, follow',
            },
            {
              name: 'description',
              content: description,
            },
            {
              property: 'og:title',
              content: title,
            },
            {
              property: 'og:description',
              content: description,
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              name: 'twitter:card',
              content: 'summary',
            },
            {
              name: 'twitter:creator',
              content: 'Sugarly Designs',
            },
            {
              name: 'twitter:title',
              content: title,
            },
            {
              name: 'twitter:description',
              content: description,
            },
            {
              name: 'keywords',
              content: keywords,
            },
          ]}
        >
          <script async src="https://www.google-analytics.com/analytics.js" />
          {context.data.cookieConsent
            && (
              <script>
                {`
                window.ga=window.ga||function()
                {(ga.q = ga.q || []).push(arguments)}
                ;ga.l=+new Date; ga('create',
                'UA-166878830-1', 'auto'); ga('send',
                'pageview');
              `}
              </script>
            )}
          {context.data.cookieConsent
            && (
            <script>
              {`
                (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1814637,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}
            </script>
            )}
        </Helmet>
      )}
    </SiteContext.Consumer>
  );
}

SEO.propTypes = {
  description: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SEO;
